(function($) {

window.site = site || {};
site.offers = site.offers || {};
site.offers.welcome15 = site.offers.welcome15 || {};

// --------
// Primary behavior responsible for initializing the welcome15 offer logic

Drupal.behaviors.ELB_welcome15 = {
  // debug config
  debug: false,         // when debugging, the overlay opens on all page loads
  showThanks: false,    // even if there's an error, show the "thank you" message
  showSignedUp: false,  // even if there's an error, show the "already signed up" message

  // storage cookie name
  offerCookie: 'welcome15',

  // # of seconds before the overlay appears
  defaultTimeout: 3000,

  // template path config
  templates: {
    form:              'welcome15_offer_signup',
    already_signed_up: 'welcome15_offer_signed_up',
    thanks:            'welcome15_offer_thanks',
    error:             'welcome15_offer_error',
    disclaimer:        'welcome15_offer_disclaimer'
  },

  // overlay config
  overlay: {
    pc: {
      className: 'welcome15-overlay',
      width: "590px",
      height: "440px",
      trapFocus:false
    },
    mobile: {
      className: 'welcome15-overlay',
      width: "100%",
      height: "100%",
      opacity: "0",
      trapFocus:false
    }
  },

  isStr: function(str) {
    return (typeof str === 'string');
  },

  isFunc: function(func) {
    return (typeof func === 'function');
  },

  isObj: function(mixed_var) {
    if (Object.prototype.toString.call(mixed_var) === '[object Array]') {
      return false;
    }
    return mixed_var !== null && typeof mixed_var === 'object';
  },

  /**
   * Helper function to get the raw contents of an JS inline template, and optionally interpolate
   * the html using Mustache
   */
  getTemplateContent: function(key, data) {
    var undef;

    // Sanitize the incoming data
    path = (key !== undef) ? key : 'foobar_template';
    data = data || false;

    // Get the html content of the template
    var html = $("script.inline-template[path='" + key + "']").html();

      if (typeof html == 'undefined') {
        return $();
      }

    if (html && html.length == 0) {
      return $();
    }

    // If there's no data object passed in, then just return a basic jquery element
    if (data == false || !$.isFunction(Mustache.render)) {
      return $(html);
    }

    // We have html and data, which means we should use Mustache to render the output
    return $(Mustache.render(html, data));
  },

  // Open a colorbox window
  openWindow: function(content, callBacks) {
    var self = this;
    content = self.isObj(content) ? content : $();

    // Dumb trick to get the entire contents of the html out of the jquery object
    content = content.wrap('<div />').parent().html();

    if (!$.isFunction($.colorbox)) {
      return false;
    }

    var args = { html: content },
        darg = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;
    $.extend(args, darg);

    if (self.isObj(callBacks)) {
      for (var k in callBacks) {
        var func = callBacks[k];
        if (self.isFunc(func)) {
          args[k] = func;
        }
      }
    }

    $.colorbox(args);
  },

  // Show the signup form
  showSignupForm: function() {
    var self = this;
    setTimeout(function() {
      var content = self.getTemplateContent(self.templates.form);
      self.openWindow(content, {
        onComplete: site.offers.welcome15.initSignUpForm
      });
    }, self.defaultTimeout);
  },

  /**
   * Helper function to determine if the welcome15 offer should appear on this page
   * @TODO: this will likely be expanded to be disabled on checkout and whatnot
   */
  showOfferOnPage: function() {
    var self       = this,
        cookieName = self.offerCookie,
        hasCookie  = $.cookie(cookieName),
        showOffer  = false,
        undef;

    // adding to allow manual disabling of the popover
    var disabled = 0;
    if (hasCookie == undef){
      // there is a div in the mustache that gets printed based on the disabled checbox input welcome15_offer_signup_v1
      var markUp = self.getTemplateContent(self.templates.form);
      var markUpDisabled = $(markUp).find('#DISABLE_POPOVER');

      // set the session cookie if the page is disabled, rather than checking every time
      if ( markUpDisabled.length > 0 ){
        $.cookie(cookieName, '1', {
          path: '/'
        });
        return false;
      }
    }

    if (self.debug || hasCookie == undef) {
      showOffer = true;
    }

    return showOffer;
  },

  // Main function to get things kicked off
  attach: function(context, settings) {
    var self       = this,
        cookieName = self.offerCookie;

    // If the user hasn't seen the popup, then show it to them
    if (self.showOfferOnPage()) {
      this.showSignupForm();
    }

    // Create the cookie
    $.cookie(cookieName, '1', {
      //expires: 365, removed to make it a session cookie
      path: '/'
    });

    // Bind to the email_signup.success event
    // When a user does the email sign up in the footer, we'll show them the welcome15 overlay
    // if the offer is still valid, and they haven't signed up already
    $(context).on('email_signup.success', function(event, rpcResponse) {
      var response  = rpcResponse.getData(),
          userinfo  = response.userinfo || {},
          templates = self.templates,
          content   = '';

      var previously_opted_in_email_promotion = userinfo.previously_opted_in_email_promotion || 0;
      var special_offer_code_status = userinfo.special_offer_code_status || 0;

      // Make sure the offer is still valid
      if (special_offer_code_status == 1) {
        // .. and only show the overlay if the user hasn't already registered
        if (previously_opted_in_email_promotion == 0) {
          content = templates.thanks;
        } else {
          content = templates.already_signed_up;
        }
      }

      if (content.length > 0) {
        // @TODO: triggering an overlay will close the special-offers-tray. We don't want it to do that.
        var html = self.getTemplateContent(content);
        self.openWindow(html);
      }
    });

    // Bind to the email_signup.error event for no particular reason except that it exists
    // Do we need to show an error message?
    $(context).on('email_signup.error', function(event, rpcResponse) {
    });

    // Bind to the cbox_complete event. This fires anytime a colorbox window is opened.
    // This event handler adds the disclaimer text to the overlays, but could be used for similiar tasks as well.
    $(context).on('cbox_complete', function() {
      var overlay  = ($('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc),
          oClass   = overlay.className,
          $element = $('#cboxContent', '#colorbox.' + oClass);

      // Make sure we're only applying this to the welcome15 overlay
      if ($element.length > 0) {
        $('.email_popover__disclaimer', $element).find('a').once('disclaimer-link').click(function(e) {
          e.preventDefault();

          var $me       = $(this),
              dClass    = 'email_popover--disclaimer',
              $content  = $me.closest('.email_popover'),
              speed     = 'fast';

          var $disclaimerOverlay = $('.' + dClass, $content);

          // The disclaimer overlay layer doesn't exist, so let's create it
          if ($disclaimerOverlay.length == 0) {
            $disclaimerOverlay = $('<div></div>').addClass(dClass).html( self.getTemplateContent(self.templates.disclaimer) );

            // ... add it to the content layer
            $disclaimerOverlay.prependTo($content);
          }

          // ... hide/show the overlay
          $disclaimerOverlay.toggle(speed, function() {
            var $this          = $(this),
                $cbox          = $('#cboxContent'),
                $cbClose       = $('#cboxClose')
                newCloseClass  = 'cboxClose--alt',
                closeButton    = $('.trans-close', $this).text();

            // "show" adds this overflow attribute automagically
            // we'll remove it, plus add the "scrollable" class
            $this 
              .css('overflow', '')
              .addClass('scrollable');

            // If the overlay is being shown, do stuff
            if ($this.is(':visible')) {
              $cbClose.hide();

              // Create a new close button, and add it to the overlay
              var $newCloseButton = $('<div />').attr('id', newCloseClass).html(closeButton);
              $newCloseButton.click(function() {
                $disclaimerOverlay.add( $(this) ).remove();
                $cbClose.show();
              });
              $newCloseButton.appendTo($cbox);
            }
          });

          return false;
        });
      }
    });
  }
};


// --------
// Callbacks and whatnot

site.offers.welcome15 = {
  behavior: Drupal.behaviors.ELB_welcome15,

  getOverlayContent: function() {
    return $('#cboxLoadedContent');
  },

  /**
   * Initialize the signup form
   */
  initSignUpForm: function() {
    var self    = site.offers.welcome15,
        overlay = self.getOverlayContent();

    overlay.find('form').submit(function(e) {
      e.preventDefault();

      var form = this;
      var fields = [
       '_SUBMIT',
       'LAST_SOURCE',
       'COUNTRY_ID',
       'LANGUAGE_ID',
       'PC_EMAIL_PROMOTIONS',
       'redirect_or_text',
       'redirect',
       'PC_EMAIL_ADDRESS'
      ];

      var paramObj = {};
      $.each(fields, function(i, name) {
        var field = $('input[name="' + name + '"]', form),
            undef;

        if (field != undef && field.length > 0) {
          paramObj[name] = field.val();
        }
      });

      generic.jsonrpc.fetch({
        method: 'rpc.form',
        params: [paramObj],
        onSuccess: function(jsonRpcResponse) {
          var response = jsonRpcResponse.getData(),
              userinfo = response.userinfo || {};

          var previously_opted_in_email_promotion = userinfo.previously_opted_in_email_promotion || 0;
          var special_offer_code_status = userinfo.special_offer_code_status || 0;

          var b = self.behavior,
              t = b.templates,
              c = t.error,                                       // Default template to return
              e = true,                                          // Defaults to the error condition
              d = { message: "<p>There's been an error.</p>" };  // Error message

          // Make sure this is still is valid offer
          if (special_offer_code_status == 1) {

            // Figure out if the user has already signed up. If so, show 'em the already_signed_up template
            c = (previously_opted_in_email_promotion == 0) ? t.thanks : t.already_signed_up;

            // And make sure there's no error to show
            e = false;
          }

          // Remove any existing content, and show the error/thanks/already_signed_up template
          overlay
            .empty()
            .append( e ? b.getTemplateContent(c, d) : b.getTemplateContent(c) );

          // Act like we're opening a new colorbox
          $(document).trigger('cbox_complete');
        },
        onFailure: function(jsonRpcResponse){
          var messages = jsonRpcResponse.getMessages();
		  // Store messages and display them in error div element.
          $('.js-popup-offer-error-messages').empty().html(messages[0].text).removeClass('hidden');
        
        }
      });

      return false;
    });
  }
};

  $(document).ready(function() {
    $('.page-utilities__signin-text').click(function() {
      $('body').addClass('overlay_on');
    });

    $(document).on('click', '#cboxClose', function() {
      $('body').removeClass('overlay_on');
    });

    $('#cboxOverlay').click(function() {
      $('body').removeClass('overlay_on');
    });
  });
})(jQuery);
